<template>
  <div class="response" v-if="response">
    <InternalHeader
      backBtn="Задачи"
      backBtnLink="/responses"
      title="Карточка задачи"
      class="response__header"
    />

    <div class="response__row">
      <div :key="updateIndex" class="response__wrapper container">
        <VacancyCard v-if="response.base_info" :response="response.base_info" />

        <h3 class="response__title">Маршрут согласования</h3>
        <StepsBlock
          @open="setActiveStageData"
          v-if="response.new_resume && response.docs"
          :activeStepCode="activeStepCode"
          :steps="response"
          class="response__steps"
        />

        <div class="response__stages" v-if="response.new_resume">
          <StagesWrapper
            v-if="activeStageData"
            :key="updateIndex"
            :data="activeStageData"
            :isDeadline="response?.deadline"
            :activeStepCode="activeStepCode"
            class="response__stages-block"
            @fileError="fileValidateError = true"
            @downloadAllDocuments="onDownloadAllDocuments"
          >
            <template #content>
              <div>
                <NewResume
                  v-if="activeStepCode === 'new_resume'"
                  :data="activeStageData"
                />
                <ResumeApprovingPerson
                  v-if="activeStepCode === 'resume_approving_persons'"
                  :data="activeStageData"
                />
                <DocsBlock
                  v-if="activeStepCode === 'docs'"
                  :data="activeStageData"
                />
                <DocsApprovingPerson
                  v-if="activeStepCode === 'docs_approving_persons'"
                  :data="activeStageData"
                />
                <MedicalBlock
                  v-if="activeStepCode === 'medical'"
                  @addInfo="addMedicalInfo"
                  :data="activeStageData"
                  :formErrors="medicalBlockErrors"
                  :showErrors="isMedicalBlockErrors"
                />
                <MedicalApprovingPerson
                  v-if="activeStepCode === 'medical_approving_persons'"
                  :data="activeStageData"
                  :tickets="response.tickets"
                />
                <TicketsBlock
                  v-if="activeStepCode === 'tickets'"
                  @changeCheckbox="changeCheckbox"
                  @addInfo="addTicketsInfo"
                  @addTickets="addTickets"
                  @openChat="openChat(response.chat_room_id)"
                  @validateFields="onTicketsBlockValidate"
                  :showErrors="isTicketBlockValid"
                  :baseInfo="response?.base_info?.resume"
                  :data="activeStageData"
                />
              </div>
            </template>
          </StagesWrapper>
        </div>
      </div>

      <div class="response__col">
        <InfoMenu
          class="response__info-menu"
          @openHistory="openHistory"
          @openChat="openChat(response.chat_room_id)"
        />
        <CandidateDuplicateElement
          v-if="response.candidate?.has_duplicate"
          @checkChanges="onCheckChanges"
        />
        <ActionsBlock
          v-if="activeStageData?.action?.length"
          class="response__actions-block"
          :actions="activeStageData.action"
          :canDelay="response?.can_delay"
          @actionClick="onActionClick"
          @transferDecision="onTransferDecisionAction"
        />
      </div>
    </div>
    <ModalWindow
      v-if="showApprovalHistoryModal"
      @close="showApprovalHistoryModal = false"
      class="response__approval-history-modal"
    >
      <ApprovalHistory
        :id="route.params.id"
        @close="showApprovalHistoryModal = false"
      />
    </ModalWindow>

    <ChatWindow
      v-if="showChat"
      :responseId="route.params.id"
      :setRoomId="response.chat_room_id"
      @close="showChat = false"
    />

    <ModalWindow
      v-if="showPopupError"
      @close="showPopupError = false"
      class="response__error-modal"
    >
      <ErrorModal
        @close="showPopupError = false"
        title="Ошибка"
        description="Проверьте подключение к Интернету."
      />
    </ModalWindow>
    <ModalWindow
      v-if="isTransferDecisionOpened"
      @close="isTransferDecisionOpened = false"
      class="response__transfer-decision-modal"
    >
      <TransferDecision
        @close="isTransferDecisionOpened = false"
        @submit="onTransferDecisionSubmit"
      />
    </ModalWindow>
    <ModalWindow
      class="response__documents-edit-modal"
      v-if="isResponseDocumentsEditOpened"
    >
      <ResponseDocumentsEditModal
        v-if="isResponseDocumentsEditOpened"
        :data="responseDocumentsEdit"
        :responseId="route.params.id"
        @success="onSuccessAttach"
        @close="isResponseDocumentsEditOpened = false"
      />
    </ModalWindow>
    <ModalWindow
      class="response__documents-edit-modal"
      v-if="isResponseMedicalEditOpened"
    >
      <ResponseMedicalEditModal
        v-if="isResponseMedicalEditOpened"
        :data="responseDocumentsEdit"
        :responseId="route.params.id"
        @success="onSuccessMedicalAttach"
        @close="isResponseMedicalEditOpened = false"
      />
    </ModalWindow>
  </div>
  <AccessClosed v-if="response.blocked_by_contract" />
</template>

<script setup>
import { onActivated, onDeactivated, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import responses from "@/api/responses";
import api from "@/api/api";
import InternalHeader from "@/components/InternalHeader";
import VacancyCard from "@/components/ResponseDetailCard/VacancyCard";
import InfoMenu from "@/components/ResponseDetailCard/InfoMenu";
import ActionsBlock from "@/components/ResponseDetailCard/ActionsBlock";
import StepsBlock from "@/components/StepsBlock";
import StagesWrapper from "@/components/ResponseDetailCard/Stages/StagesWrapper";
import NewResume from "@/components/ResponseDetailCard/NewResume";
import ResumeApprovingPerson from "@/components/ResponseDetailCard/ResumeApprovingPerson";
import DocsBlock from "@/components/ResponseDetailCard/DocsBlock";
import DocsApprovingPerson from "@/components/ResponseDetailCard/DocsApprovingPerson";
import MedicalBlock from "@/components/ResponseDetailCard/MedicalBlock";
import MedicalApprovingPerson from "@/components/ResponseDetailCard/MedicalApprovingPerson";
import TicketsBlock from "@/components/ResponseDetailCard/TicketsBlock";
import ApprovalHistory from "@/components/ModalWindow/ModalBodyes/ApprovalHistory";
import ChatWindow from "@/components/ChatWindow";
import ErrorModal from "@/components/ModalWindow/ModalBodyes/ErrorModal";
import TransferDecision from "@/components/ModalWindow/ModalBodyes/TransferDecision";
import AccessClosed from "@/components/AccessClosed";
import CandidateDuplicateElement from "@/components/CandidateDuplicateElement";
import ResponseDocumentsEditModal from "@/components/ModalWindow/ModalBodyes/ResponseDocumentsEditModal";
import ResponseMedicalEditModal from "@/components/ModalWindow/ModalBodyes/ResponseMedicalEditModal";
import { debounce } from "@/services/helpers";
const route = useRoute();
const router = useRouter();

const response = ref({});
const showChat = ref(false);
const chatId = ref(null);
const showPopupError = ref(false);
const showDublicateElement = ref(false);
const isResponseDocumentsEditOpened = ref(false);
const isResponseMedicalEditOpened = ref(false);
const responseDocumentsEdit = ref();
const medicalBoard = ref({
  description: "",
  file: null,
});
const tickets = ref({
  description: "",
  file: null,
});
const ticketBlockErrors = ref({});
const medicalBlockErrors = ref({
  files: "",
  description: "",
});
const isTicketBlockValid = ref(false);
const isMedicalBlockErrors = ref(false);
const fileValidateError = ref(false);

const showApprovalHistoryModal = ref(false);

const activeStepCode = ref(null);
const isTransferDecisionOpened = ref(false);
const isTransferDecisionLoading = ref(false);

const openChat = (id) => {
  if (!navigator.onLine) {
    showPopupError.value = true;
    return;
  }
  chatId.value = id;
  showChat.value = true;
};

const openHistory = () => {
  if (!navigator.onLine) {
    showPopupError.value = true;
    return;
  }
  showApprovalHistoryModal.value = true;
};

const onCheckChanges = () => {
  router.push(`/candidates/${response.value?.candidate?.id}`);
};

const updateIndex = ref(1);

const onTicketsBlockValidate = (formErrors) => {
  ticketBlockErrors.value = formErrors;
};

const validateTicketsBlock = () => {
  return !Object.values(ticketBlockErrors.value).some((val) => !!val);
};

const getResponse = async () => {
  try {
    response.value = await responses.getResponse(route.params.id);
    activeStepCode.value = response.value.step_code;
    if (activeStepCode.value === "new_resume")
      activeStageData.value = response.value[activeStepCode.value];
    if (activeStepCode.value === "resume_approving_persons")
      activeStageData.value = response.value[activeStepCode.value];
    if (activeStepCode.value === "docs")
      activeStageData.value = response.value[activeStepCode.value];
    if (activeStepCode.value === "medical")
      activeStageData.value = response.value[activeStepCode.value];
    if (activeStepCode.value === "medical_approving_persons")
      activeStageData.value = response.value[activeStepCode.value];
    if (activeStepCode.value === "tickets")
      activeStageData.value = response.value[activeStepCode.value];

    updateIndex.value++;
  } catch (error) {
    console.log(error);
  }
};

const debouncedGetResponse = debounce(() => getResponse(), 200);

const update = async () => {
  fileValidateError.value = false;
  await getResponse();

  medicalBoard.value = {
    description: "",
    direction: null,
  };
};

const activeStageData = ref(null);
const setActiveStageData = (val) => {
  activeStepCode.value = val.stepCode;
  activeStageData.value = val.data;
};

const medicalInfo = ref({});
const ticketsInfo = ref({});
const ticketsBody = ref({});
const addMedicalInfo = (body) => {
  medicalInfo.value = body;
  isMedicalBlockErrors.value = false;
};
const addTicketsInfo = (body) => {
  ticketsInfo.value = body;
};

const attachDocuments = (structuredDocuments, deletedDocuments, step) => {
  const stepsMap = {
    documents: 101,
    medical: 102,
  };
  const formData = new FormData();

  if (step === "documents") {
    structuredDocuments.forEach((section, sectionIndex) => {
      section.downloaded.forEach((doc, docIndex) => {
        if (!doc?.id) {
          console.log("section", section, doc);
          formData.append(`files[${section.field_id}][]`, doc);
        }
      });
    });

    if (deletedDocuments?.length) {
      deletedDocuments.forEach((id) => {
        formData.append(`files_for_delete[]`, id);
      });
    }
  } else if (step === "medical") {
    structuredDocuments.forEach((doc, docIndex) => {
      if (!doc?.id) {
        console.log("doc", doc);
        formData.append(`files[]`, doc);
      }
    });

    if (deletedDocuments?.length) {
      deletedDocuments.forEach((id) => {
        formData.append(`files_for_delete[]`, id);
      });
    }
  }

  console.log("formData", formData);

  responses
    .setStatusWithDocuments(route.params.id, stepsMap[step], formData)
    .then((res) => {
      if (res) {
        getResponse();
      }
    });
};
const onSuccessAttach = (structuredDocuments, deletedDocuments) => {
  isResponseDocumentsEditOpened.value = false;
  attachDocuments(structuredDocuments, deletedDocuments, "documents");
};

const onSuccessMedicalAttach = (structuredDocuments, deletedDocuments) => {
  isResponseMedicalEditOpened.value = false;
  attachDocuments(structuredDocuments, deletedDocuments, "medical");
};

const validateMedicalBlock = () => {
  if (!medicalInfo.value?.files || medicalInfo.value?.files.length === 0) {
    medicalBlockErrors.value.files = true;
  } else {
    medicalBlockErrors.value.files = false;
  }
  if (medicalInfo.value?.showMemo && !medicalInfo.value.description) {
    medicalBlockErrors.value.description = true;
  } else {
    medicalBlockErrors.value.description = false;
  }
  return !Object.values(medicalBlockErrors.value).some((val) => !!val);
};

const addTickets = (body) => {
  ticketsBody.value = body;
};

const checkboxValue = ref(true);
const changeCheckbox = async (val) => {
  checkboxValue.value = val;
  const formData = new FormData();
  if (
    activeStageData.value.status_id === 26 ||
    activeStageData.value.status_id === 28
  ) {
    formData.append("available_tickets", checkboxValue.value);
  }
  const response = await responses.postStatus(
    route.params.id,
    activeStageData.value.status_id,
    formData
  );

  await getResponse();
};

const onActionClick = async (body) => {
  const formData = new FormData();
  console.log("onActionClick body", body);
  if (body.use === "NEED_SET_DOCUMENTS_POPUP") {
    responses.getResponseDocuments(route.params.id, "documents").then((res) => {
      console.log("onActionClick res", res);
      if (res?.data) {
        responseDocumentsEdit.value = res?.data;
        isResponseDocumentsEditOpened.value = true;
      }
    });
    // responseDocumentsEdit.value = response.value?.[response.value?.step_code]
    return;
  }

  if (body.use === "NEED_SET_MEDICAL_POPUP") {
    responses.getResponseDocuments(route.params.id, "medical").then((res) => {
      console.log("onActionClick res", res);
      if (res?.data) {
        responseDocumentsEdit.value = res?.data;
        isResponseMedicalEditOpened.value = true;
      }
    });
    // responseDocumentsEdit.value = response.value?.[response.value?.step_code]
    return;
  }

  if (activeStepCode.value === "medical" && body.id === 17) {
    isMedicalBlockErrors.value = !validateMedicalBlock();

    if (isMedicalBlockErrors.value) {
      return;
    }

    Object.entries(medicalInfo.value).forEach(([key, val]) => {
      if (key === "files") {
        val.forEach((file) => {
          formData.append("direction[]", file);
        });
      } else if (key === "showMemo") {
        // continue;
      } else {
        formData.append(key, val);
      }
    });
  }

  if (activeStepCode.value === "tickets" && body.id === 27) {
    const validatedTicketsBlock = validateTicketsBlock();

    if (!validatedTicketsBlock) {
      isTicketBlockValid.value = !validatedTicketsBlock;
      return;
    } else {
      formData.append("departure_city", ticketsInfo.value.departure_city);
      formData.append("departure_date", ticketsInfo.value.departure_date);
      formData.append("passport[]", ticketsInfo.value.file);
    }
  }

  if (
    activeStepCode.value === "tickets" &&
    body.id === 29 &&
    activeStageData.value.employee.is_me
  ) {
    const validatedTicketsBlock = validateTicketsBlock();
    if (!validatedTicketsBlock) {
      isTicketBlockValid.value = !validatedTicketsBlock;
      return;
    } else {
      formData.append("arrival_date", ticketsBody.value.date);
      formData.append("arrival_time", ticketsBody.value.time);

      ticketsBody.value.files.forEach((file) => {
        formData.append("tickets[]", file);
      });
    }
  }

  if (activeStepCode.value === "tickets" && body.id === 30) {
  }

  formData.append(
    "rejection_reason",
    `
            Благодарим за ваш отклик.
            Мы внимательно ознакомились
            с вашим резюме. К сожалению,
            на данном этапе мы не сможем предложить вам работу.
            Искренне желаем удачи в поисках работы!
    `
  );

  const postStatusResponse = await responses.postStatus(
    route.params.id,
    body.id,
    formData
  );

  if (
    (activeStageData.value?.need_redirect &&
      !activeStageData.value?.employee?.is_me) ||
    postStatusResponse?.need_redirect
  ) {
    await router.push("/responses");
  } else {
  }

  if (body.id === 34) {
    router.push("/responses");
    return;
  }

  await getResponse();
};

const onTransferDecisionAction = () => {
  isTransferDecisionOpened.value = true;
};

const onTransferDecisionSubmit = async (data) => {
  if (isTransferDecisionLoading.value) return;
  isTransferDecisionLoading.value = true;
  const body = {
    deadline: data?.date.toISOString().slice(0, 10),
  };
  const response = await responses
    .transferDecision(route.params.id, body)
    .then(() => getResponse())
    .finally(() => {
      isTransferDecisionOpened.value = false;
      isTransferDecisionLoading.value = false;
    });
};

const onDownloadAllDocuments = async (activeStepCode) => {
  if (activeStageData.value?.documents) {
    let link = document.createElement("a");
    link.setAttribute("download", "");
    link.setAttribute("data-href", "#");
    document.body.appendChild(link);
    activeStageData.value.documents.forEach((document, index) => {
      setTimeout(() => {
        if (window.navigator.userAgent.indexOf("Firefox") > -1) {
          window.open(document.path);
        } else {
          link.href = document.path;
          link.click();
        }
      }, 300 * index);
    });
    document.body.removeChild(link);
  }
};
onActivated(() => {
  window.scrollTo(0, -10000);
  debouncedGetResponse();
});

onDeactivated(() => {
  response.value = {};
});

onMounted(() => {
  if (!response.value?.response) {
    debouncedGetResponse();
  }
});
</script>

<style scoped lang="scss">
.response {
  padding-bottom: 32px;

  &__header {
    margin-top: 12px;
  }

  &__row {
    margin-top: 24px;
    display: flex;
  }

  &__col {
    width: 256px;
    margin-left: 32px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: auto;
    justify-content: space-between;
    // min-height: calc(100vh - 200px);
  }

  &__stretch-block {
    flex: 1 1 auto;
    width: 100%;
    min-height: 200px;
    height: 100%;
  }

  &__comments-block {
    margin-top: 106px;
  }

  &__actions-block {
    margin-top: auto;
  }

  &__title {
    margin-top: 32px;
    font-size: 24px;
    font-weight: 700;
  }

  &__steps {
    margin-top: calc(16px + 6px);
  }

  &__stages {
    margin-top: 16px;
  }

  &__stages-block {
    width: 100%;
  }

  &__approval-history-modal {
    &::v-deep(.modal__body) {
      width: 626px;
    }
  }

  &__error-modal {
    &::v-deep(.modal__body) {
      width: 304px;

      .modal-body__description {
        max-width: 300px;
      }
    }
  }

  &__transfer-decision-modal {
    &::v-deep(.modal__body) {
      width: 544px;
      border-radius: 16px;
    }
  }

  &__documents-edit-modal {
    &::v-deep(.modal__body) {
      width: 832px;
      border-radius: 16px;
      height: 457px;
    }
  }

  &__info-menu {
    margin-bottom: 24px;
  }
}
</style>
