<template>
  <div class="response-documents">
    <div class="response-documents__header">
      <h2 class="response-documents__title">Документы</h2>
      <div class="response-documents__close" @click.prevent="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 5L5 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5 5L15 15"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="response-documents__body">
      <div class="response-documents__form">
        <!-- <h3 class="response-documents__form-title">Данные кандидата</h3>
          <div class="response-documents__form-row_wrap"> -->
        <!-- <VFile :file="form.file"/> -->

        <template v-if="structuredDocuments?.length">
          <section
            class="response-documents__form-section"
            v-for="(section, sectionIndex) in structuredDocuments"
            :key="sectionIndex"
          >
            <h3 class="response-documents__form-title">
              {{ section?.title }}{{ section?.is_required ? "*" : ''}}
            </h3>
            <div class="response-documents__form-row_wrap">
              <!-- <VUploadFile
                :deleteBtn="true"
                @deleteFile="onDeleteFile(index)"
                v-model="form.files[index]"
                :key="index"
                :namePropertyField="'documents_filename'"
              /> -->

              <VUploadFile
              v-for="(document, index) in section?.downloaded"
                :deleteBtn="true"
                @deleteFile="onDeleteFile(sectionIndex, index)"
                v-model="section.downloaded[index]"
                :key="document.size"
                :namePropertyField="'filename'"
              />
              <!-- <VUploadFile
                :deleteBtn="true"
                @deleteFile="onDeleteFile(sectionIndex, index)"
                v-model="section.downloaded[section.downloaded?.length]"
                :namePropertyField="'filename'"
              /> -->
              <div class="response-documents__upload-files-btn" v-if="section.downloaded?.length < section.settings?.max_count">
                <input @change="(event) => uploadFile(event, sectionIndex)" type="file" :accept="computedTypes(section.settings?.types)"/>
                <img src="@/assets/images/icons/upload.svg" alt="upload" />
                Загрузить файл
              </div>
            </div>
            <p v-if="v$?.sections?.[sectionIndex]?.downloaded?.$error" class="response-documents__validation-error">Добавьте обязательный документ</p>
          </section>
        </template>

        <!-- <template v-if="structuredDocuments">
          <section
            class="response-documents__form-section"
            v-for="(section, sectionIndex) in structuredDocuments"
            :key="section?.length"
          >
            <h3 class="response-documents__form-title">
              {{ sectionIndex }}{{ section?.[0].is_required ? "*" : ''}}
              {{ section?.length }}
            </h3>
            <div class="response-documents__form-row_wrap">
              <VUploadFile
              v-for="(document, index) in section?.downloaded"
                :deleteBtn="true"
                @deleteFile="onDeleteFile(sectionIndex, index)"
                v-show="form.files?.[index]"
                v-model="form.files[index]"
                :key="index + section?.length"
                :namePropertyField="'documents_filename'"
              />
              <VUploadFile
              :deleteBtn="true"
              v-model="form.files[form.files.length]"
              v-if="form.files?.length < 10"
            />
            </div>
          </section>
        </template> -->

        <!-- <VUploadFile
          :deleteBtn="true"
          v-model="form.files[form.files.length]"
        /> -->
      </div>
      <div class="response-documents__btns">
        <VButton
          label="Отмена"
          bg="#E4E7EE"
          bgHover="#BBC4CD"
          color="#1E3959"
          colorHover="#1E3959"
          class="response-documents__btn"
          @click="$emit('close')"
        />

        <VButton
          label="Прикрепить документы"
          bg="#0D5FCB"
          color="#fff"
          class="response-documents__btn"
          @click="attachDocuments"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import responses from "@/api/responses";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import common from "@/api/common";
import { debounce } from "@/services/helpers";
const emit = defineEmits(["close", "resumeCreated", "success"]);
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
  responseId: {
    type: [String, Number],
    required: false
  }
});

const form = reactive({
  files: [],
  sections: [],
  downloaded: [],
});

const structuredDocuments = ref();
const deletedDocuments = ref([])
const resultsResidence = ref([]);

const typeFormat = {
  "jpg,jpeg,png,pdf" : '.jpg, .jpeg, .png, .pdf'
}

const computedTypes = computed(() => (type) => {
  return typeFormat[type]
})
const rules = computed(() => {
  const rules = {
    sections: {},
    // downloaded: {},
  };

  if(structuredDocuments.value?.length > 0){
    structuredDocuments.value.forEach((section, index) => {
      if(section?.is_required	&& !section?.downloaded?.length){
        rules.sections[index] = {
          downloaded: {required}
        }
      }
    })
  }
  // if (!form.files?.length) {
  //   // form.files.forEach((exp, index) => {
  //   //   rules.experience[index] = {
  //   //     company: { required },
  //   //     specialty: { required },
  //   //     begin_date: { required },
  //   //     ...(!form.experience?.[index]?.isStillWork && {
  //   //       end_date: { required },
  //   //     }),
  //   //   };
  //   // });
  // }

  return rules;
});
// const v$ = useVuelidate(rules, form);
const v$ = useVuelidate(rules, structuredDocuments);

const onDeleteFile = (sectionIndex, index) => {
  console.log('onDeleteFile = (sectionIndex, index)',sectionIndex, index)
  console.log('onDeleteFile structuredDocuments.value[sectionIndex]',structuredDocuments.value[sectionIndex])
  if(structuredDocuments.value?.[sectionIndex].downloaded?.[index]?.id){
  deletedDocuments.value.push(structuredDocuments.value[sectionIndex].downloaded[index].id) 
  }
  // delete structuredDocuments.value[sectionIndex].downloaded[index]
  structuredDocuments.value[sectionIndex].downloaded = structuredDocuments.value[sectionIndex].downloaded.filter((_ ,idx) => idx !== index)
  console.log('onDeleteFile structuredDocuments.value',structuredDocuments.value)
  // setTimeout(() => {
  // structuredDocuments.value[sectionIndex].downloaded = structuredDocuments.value[sectionIndex].downloaded.filter((doc) => !!doc)
  // console.log('onDeleteFile structuredDocuments.value[sectionIndex].downloaded',structuredDocuments.value[sectionIndex].downloaded)
  // }, 20);
};


const uploadFile = (e,sectionIndex) => {
  const sectionsettings = structuredDocuments.value?.[sectionIndex]?.settings
  console.log('uploadFile e,sectionIndex', e,sectionIndex)
  const file = e.target?.files?.[0];
  const fileSize = (file.size / 1024 / 1024)
  const fileType = file.type.split('/')
  console.log('fileType',fileType)
  console.log('uploadFile file', file)

  if(sectionsettings){
    if(fileSize > sectionsettings.max_size_mb){
      console.log('no size',fileSize > sectionsettings.max_size_mb)
      return
    }
    if(!sectionsettings.types.split(',').some(type => fileType.includes((type)) )){
      console.log('no type',!fileType.includes(sectionsettings.types))
      return
    }
  }
  structuredDocuments.value[sectionIndex].downloaded.push(file)
  console.log('structuredDocuments.value[sectionIndex].downloaded', structuredDocuments.value[sectionIndex].downloaded)
  
}
const attachDocuments = async () => {
  const validate = await v$.value.$validate();
  if (!validate) {
    return;
  }

  emit('success',structuredDocuments.value,deletedDocuments.value)

  // const formData = new FormData()

  // structuredDocuments.value.forEach((section, sectionIndex) => {
  //   section.downloaded.forEach((doc, docIndex) => {
  //     if(!doc?.id){
  //       console.log('section',section, doc)
  //     formData.append(`files[${section.field_id}][]`, doc)
  //     }
  //   })
  // })

  // if(deletedDocuments.value?.length){
  //   deletedDocuments.value.forEach((id) =>{
  //   formData.append(`files_for_delete[]`, id) 
  //   })
  // }

  // console.log('formData', formData)

  // responses.setStatusWithDocuments(props.responseId, 101, formData).then((res) => {
  //   if(res){
  //     emit('success')
  //   }
  // })
}

const strucutreDocuments = (documents) => {
  const structure = {};
  documents.forEach((document) => {
    structure[document.title] = structure[document.title]
      ? [...structure[document.title], document]
      : [document];
  });

  console.log("structure", structure);

  return structure;
};

const searchResidence = async () => {
  const res = await common.searchResidence(form.residence.name);
  if (res?.data)
    resultsResidence.value = res?.data.map((item) => {
      return {
        ...item,
        name: item?.regionDescription
          ? `${item?.regionDescription} ${item?.name}`
          : `${item?.name}`,
      };
    });
};

// const debouncedSearchResidence = debounce(() => searchResidence(), 400);

onMounted(() => {
  console.log("props.data", props.data);
  if (props?.data) {
    // form.files = props?.data;
    // structuredDocuments.value = strucutreDocuments(props?.data);
    structuredDocuments.value = props?.data

  }
});
</script>
<style lang="scss" scoped>
.response-documents {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  :deep(.v-filtration) {
    border-radius: 4px;
    background-color: $light;
  }
  :deep(.v-filtration__filter) {
    width: 186px;
    border-radius: 4px;
  }
  :deep(.v-filtration__filter_active) {
    .v-filtration__label {
      font-weight: 400;
    }
  }

  &__header {
    border-bottom: 1px solid $light-gray-1;
    padding: 13px 24px 12px;
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding: 24px;
    // max-height: 381px;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  &__icon {
    height: 56px;
    width: 56px;
    flex: 0 0 auto;
    align-self: center;
    margin-bottom: 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
  }

  &__search-block {
    margin-bottom: 24px;
  }

  &__search-input {
    width: 448px;
    border: 1px solid $light-gray-1;

    &::v-deep(.v-input__input) {
      &::placeholder {
        color: $dark-blue;
        opacity: 1;
      }
    }
  }
  &__input {
    &_half {
      max-width: calc(25%);
    }
    &_date {
      flex: 1 1 auto;
    }

    &_disabled {
      pointer-events: none;
      user-select: none;
      color: $dark-blue;
      :deep(.v-input__input) {
        color: $dark-blue;
      }
    }
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__form {
    flex: 1 1 auto;
  }

  &__form-section {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__form-title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }
  &__form-subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
  }

  &__form-row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    &_wrap {
      display: flex;
      column-gap: 12px;
      row-gap: 16px;
      margin-bottom: 16px;
      flex-wrap: wrap;
    }
    &_justify {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }
    &_dates {
      display: flex;
      gap: 16px;
      max-width: calc(50% - 8px);
      flex: 1 1 auto;
    }
  }
  &__form-button-wrapper {
    display: flex;
    align-items: center;
    height: 32px;
  }
  &__form-button {
    color: $blue;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    text-align: right;
    &_delete {
      color: $red;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: right;
    }
  }
  &__form-button-icon {
    margin-right: 4px;
  }

  &__form-field {
    width: 100%;
    max-width: calc(50% - 8px);
    :deep(.v-input) {
      width: 100%;
    }
    &_date {
      width: 100%;
      :deep(.v-input) {
        width: 100%;
      }
    }
  }

  &__form-field-error {
    margin-top: 4px;
    font-size: 14px;
    color: $red;
  }
  &__input {
    flex: 0 1 calc(50% - 8px);
  }
  &__switch {
    flex: 0 1 calc(50% - 8px);
  }
  &__btns {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    &:first-child {
      width: calc(50% - 8px);

      &:active {
        background-color: $gray !important;
      }
    }

    &:last-child {
      width: calc(50% - 8px);

      &:active {
        background-color: #0a3489 !important;
      }
    }
  }

  &__upload-files-btn {
    position: relative;
    width: 187px;
    height: 56px;
    padding: 0 23.5px;
    border-radius: 8px;
    background-color: $primary-blue;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 24px;
      margin-right: 8px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }
  }

  &__validation-error {
    margin-bottom: 4px;
    font-size: 14px;
    color: $red;
  }

  :deep(.v-checkbox) {
    flex: 0 0 auto;
    margin-left: auto;
  }
}
</style>
